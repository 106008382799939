<template>
	<Header/>
	<div class="mainPageContainer">
    <h1>{{ $t('general.title') }}</h1>
    <div v-html="$t('home.introTextMobile')"></div>
    <button v-if="year && isMobile" class="timeTravelButton heartbeat" @click="goToXrPage()">
      <span class='glowing-txt'>{{ $t('home.buttonText', { year: year}) }}</span><br />
      <span style="font-size: 14px;">{{ $t('home.buttonHint') }}</span>
    </button>
    <button v-if="year && !isMobile" class="timeTravelButton" @click="showPoster()">{{ $t('home.showPoster', { year: year}) }}</button>
    <!--
    <h2>{{ $t('home.raffleTitle') }}</h2>
    <div v-html="$t('home.raffleText')"></div>
    -->
    <h2>{{ $t('home.posterTitle') }}</h2>
    <div class="mapContainer">
      <Map />
    </div>
    <a class="openMapLink" href="" @click="$router.push('/map?year=' + year)">{{$t('home.openMapLinkText')}}</a>
    <button v-if="year && isMobile" class="timeTravelButton heartbeat" @click="goToXrPage()">
      <span class='glowing-txt'>{{ $t('home.buttonText', { year: year}) }}</span><br />
      <span style="font-size: 14px;">{{ $t('home.buttonHint') }}</span>
    </button>
  </div>
  <div v-if="posterOverlay" id="posterOverlay" @click="posterOverlay = false">
    <div class="inner">
      <h2>{{ $t('home.scanPoster') }}</h2>
      <div class="posterBox">
        <img :src="poster" alt="das aktuelle Plakat der 57. Hofer Filmtage" />
      </div>
    </div>
  </div>
	<Footer />
</template>


<script>
import { defineComponent, ref } from 'vue';
import Footer from "../components/Footer.vue";
import poster from "@/assets/imgs/Plakat2024_desktop.jpg";
import * as Utils from '@/helper/utils'
import { mapState } from 'pinia'
import { useCookieStore } from '../stores/cookiestore'
import Map from "@/components/Map.vue";
import Header from "@/components/Header.vue";

export default defineComponent( {
  setup() {
		const mySplide = ref();
		return {
			poster
		};
	},
	components: {
		Header,
    Footer,
		Map
  },
	data () {
		return {
			isMobile: true,
      year: "1967",
      posterOverlay: false
		}
	},
	created() {
    if(this.$route.query.year) {
      this.year = this.$route.query.year
    }
		this.isMobile = Utils.mobileAndTabletCheck() 
	},
	methods: {
    goToXrPage() {
      if (this.isAndroidFirefox) {
        window.location = Utils.getUrl(this.year, this.consentGiven, this.$i18n.locale)
      } else { 
        this.$router.push({
          path: '/xr-view',
          query: { year: this.year }
        })
      }
    },
    showPoster() {
      this.posterOverlay = true
    }
  },
  computed: {
    isAndroidFirefox() {
      return /Android.+Firefox\//.test(navigator.userAgent)
    },
    ...mapState(useCookieStore, ['allowUsingApp', 'consentGiven']),
  }
});
</script>
<style scoped>
.mapContainer {
  height: 300px;
  width: 100%;
  overflow: hidden;
  margin: 1rem 0 0 0;
}
.openMapLink {
  display: block;
  margin-bottom: 24px;
}

#posterOverlay {
  width: 100%;
  height: 100%;
  background-color: var(--hof-color-red);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  padding: 40px 0;
  color: var(--hof-color-white);
}
#posterOverlay .inner{
  max-width: 800px;
  margin: auto;
  text-align: center;
}
#posterOverlay .inner h2 {
  text-align: center;
}

#posterOverlay .inner .posterBox {
  max-height: calc(100vh - 280px);
  height: 100vh;
  position: relative;
}
#posterOverlay .inner .posterBox img {
  height: 100%;
}
</style>